jQuery(document).ready(function( $ ) {
  // Back to top button
  $(window).scroll(function() {
    if ($(this).scrollTop() > 100) {
      $('.back-to-top').fadeIn('slow');
    } else {
      $('.back-to-top').fadeOut('slow');
    }
  });
  $('.back-to-top').click(function(){
    $('html, body').animate({scrollTop : 0},1500, 'easeInOutExpo');
    return false;
  });

  // Initiate the wowjs animation library
  new WOW().init();
  // Header scroll class
  $(window).scroll(function() {
    if ($(this).scrollTop() > 100) {
      $('#header').addClass('header-scrolled');
    } else {
      $('#header').removeClass('header-scrolled');
    }
  });


  $(".carousel").swipe({
    swipe: function(event, direction, distance, duration, fingerCount, fingerData) {
      if (direction == 'left') $(this).carousel('next');
      if (direction == 'right') $(this).carousel('prev');
    },
    allowPageScroll:"vertical"
  });

  
  // jQuery counterUp (used in Facts section)
  $('[data-toggle="counter-up"]').counterUp({
    delay: 10,
    time: 1000
  });

  // Porfolio isotope and filter
  var portfolioIsotope = $('.portfolio-container').isotope({
    itemSelector: '.portfolio-item',
    layoutMode: 'fitRows'
  });

  $('#portfolio-flters li').on( 'click', function() {
    $("#portfolio-flters li").removeClass('filter-active');
    $(this).addClass('filter-active');

    portfolioIsotope.isotope({ filter: $(this).data('filter') });
  });


   //close hamburger
    // $(document).on("click", function(e){
    //    var navicon = $('#header .sidenav-icon');
    //   if( $(e.target).closest("#mySidenav").length == 0 && $("#mySidenav").width() != '0'){
    //     if(!$(e.target).hasClass('paginate_button')){
    //       document.getElementById("mySidenav").style.width = "0";
    //       if ($(window).width() > 424) {
    //         $(".loggedinheadericon").css("display","block");
    //       }
    //     }
    //     $("html").css("overflow-y", "scroll");
    //     navicon.addClass('fa-bars').removeClass('fa-times').css('color', '#4a4a4a');
    //   }
    // });
    
});

function openNav() {
  var navicon = $('#header .sidenav-icon');
  if(navicon.hasClass('fa-bars')) {
    $("#mySidenav").css("border","1px solid #ccc");
    $("#mySidenav").css("z-index","12");
    $(".loggedinheadericon").css("display","none");
    if ($(window).width() < 754) {
      $('#mySidenav').css("width","100%");
      $('#mySidenav').css("padding-top",18);
  
    }else{
      $('#mySidenav').css("width","32%");
      $('#mySidenav').css("padding-top",$('#header').height());
  
    }
//to stop screen from shifting    $("html").css("overflow-y", "hidden");
    navicon.addClass('fa-times').removeClass('fa-bars').css('color', '#4a4a4a');
  } else {
    $('#mySidenav').css("width","0%");
    if ($(window).width() > 424) {
      $(".loggedinheadericon").css("display","block");
    }
    $("html").css("overflow-y", "scroll");
    navicon.addClass('fa-bars').removeClass('fa-times').css('color', '#4a4a4a');
    
  }
}



